@value foam, funGreen, selago, paleSky, earlyDawn, vesuvius from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.pill {
    padding: 2px 10px 3px;
    border-radius: 999px;
    color: funGreen;
    font-size: to-rem(12px);
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    background-color: foam;
}

.disabled {
    background-color: selago;
    color: paleSky;
}

.warning {
    background-color: earlyDawn;
    color: vesuvius;
}