@value screenSm from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value eigengrau, christine, paleSky, mischka, oxfordBlue, linkWater from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value Lato from 'src/shared/design-system/thermal-ceramics/fonts/lato/styles.module.css';

.navigation {
    overflow: auto;
    max-width: 100%;
}

.list {
    display: flex;
    align-items: flex-start;
    composes: listReset from 'src/shared/design-system/thermal-ceramics/helpers/list-reset.module.css';
    font-family: Lato;
}

.item {}

.link {
    display: block;
    padding: 0.875rem 1.25rem 0.75rem;
    border-bottom: 0.125rem solid transparent;
    color: paleSky;
    font-size: 0.875rem;
    line-height: 1.2;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.02em;
    transition: all .3s ease;
}

.link:hover {
    color: eigengrau;
}

.active {
    color: eigengrau;
    border-bottom-color: christine;
}

.secondary {
    display: flex;
    align-items: flex-start;
}

.secondary .list {
    display: flex;
    border: 1px solid mischka;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.secondary .link {
    padding: 10px 16px;
    border: none;
    border-radius: 0;
    color: oxfordBlue;
    background-color: white;
    font-size: to-rem(14px);
    font-family: Lato;
    font-weight: 500;
    line-height: 1.43;
    text-transform: none;
    background-color: white;
}

.secondary .active {
    background-color: linkWater;
}

.secondary .item:not(:last-child) .link {
    border-right: 1px solid mischka;
}