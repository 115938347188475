@value flamingo, pastelGray from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.buttonContainer {
    z-index: 999;
    position: relative;
}

.button {
    padding: 0;
    border: 0;
    background: none;
    color: flamingo;
    text-align: left;
    outline: none;
}

.popoverElement {
    z-index: 999;
}

.content {
    max-width: 200px;
    padding: 4px 6px;
    border: 1px solid pastelGray;
    border-radius: 8px;
    background: white;
    font-size: 14px;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03);
}