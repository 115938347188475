@value white, sweetPink, athensGray, paleSky, blueHaze, eigengrau, downriver, linkWater from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value Lato from 'src/shared/design-system/thermal-ceramics/fonts/lato/styles.module.css';

.checkbox {
    display: flex;
    position: relative;
    gap: 12px;
}

.input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.check {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: to-rem(20px);
    height: to-rem(20px);
    border: 1px solid blueHaze;
    border-radius: 4px;
    background: white;
    color: transparent;
}

.label {
    color: eigengrau;
    font-family: Lato;
    font-size: to-rem(14px);
    line-height: 1.43;
}

.icon {
    display: block;
    width: to-rem(12px);
    height: to-rem(8px);
}

.input:checked ~ .check {
    color: downriver;
    background-color: linkWater;
}

.input:focus-visible ~ .check {
    border-color: sail;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 2px 4px rgba(187, 205, 251, 0.6);
}

.input:disabled:checked ~ .check {
    color: blueHaze;
    background-color: athensGray;
}

.input:disabled ~ .label {
    color: paleSky;
}

.invalid .check {
    border-color: sweetPink;
}

.invalid .input:focus-visible ~ .check {
    border-color: sweetPink;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #FEE4E2;
}
