@value screenXsMax from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';

.grid {
    margin-bottom: 60px;
}

.fillWithUser,
.notification,
.fieldset:not(:last-child) {
    margin-bottom: 24px;
}

.fieldset {
    max-width: 100%;
    min-width: 0;
}

.innerGrid {
    display: flex;
    flex-direction: column;
    gap: 28px;
}

.warning {
    margin-bottom: 24px;
}

@media screenXsMax {
    .fieldset:not(:last-child) {
        margin-bottom: 48px;
    }
}