@value trout from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value screenXs from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';

.accreditations {
    --gap: 1rem;
    --column-width: 33.33%;
    --column-width-without-gap: calc(var(--column-width) - var(--gap));

    composes: listReset from 'src/shared/design-system/thermal-ceramics/helpers/list-reset.module.css';
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: var(--gap);
}

.item {
    flex: 0 0 var(--column-width-without-gap);
    max-width: var(--column-width-without-gap);
}

.accreditation {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.625rem;
    text-align: center;
    color: trout;
    text-decoration: none;
    font-size: 0.875rem;
    line-height: 1.42;
}

.image {
    height: 4.25rem;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

@media (max-width: screenXs) {
    .accreditations {
        --column-width: 50%;
    }

    .image {
        height: 2.875rem;
    }
}