@value screenXsMax from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';

.actions {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
}

.actions:not(:first-child) {
    margin-top: 32px;
}

@media screenXsMax {
    .actions {
        display: flex;
        flex-direction: column;
    }
}