@value christine from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.spinner {
    --color: christine;
    --animation-time: 1.2s;
    --thickness: 4px;

    display: block;
    position: relative;
}

.spinner::before {
    content: '';
    display: block;
    padding-top: 100%;
}

.spinner::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-width: var(--thickness);
    border-style: solid;
    border-color: var(--color) transparent var(--color) transparent;
    animation: spin var(--animation-time) linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}