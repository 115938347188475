@value white, eigengrau, clementine, paleSky from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value Lato from 'src/shared/design-system/thermal-ceramics/fonts/lato/styles.module.css';

.container {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.wrap {
    display: flex;
    min-height: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.panel {
    margin: auto;
    width: 335px;
    padding: 24px;
    box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08), 0 8px 8px -4px rgba(16, 24, 40, 0.03);
    background-color: white;
    border-radius: 12px;
}

.title {
    margin: 0 0 4px;
    color: eigengrau;
    font-family: Lato;
    font-size: to-rem(18px);
    font-weight: 600;
    line-height: 1.56;
}

.description {
    margin: 0;
    color: paleSky;
    font-family: Lato;
    font-size: to-rem(14px);
    line-height: 1.43;
}

.backdrop {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.backdropInner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: rgba(0, 0, 0, 0.25);
}

.header {
    margin-bottom: 20px;
}

.body:not(:last-child) {
    margin-bottom: 32px;
}

.body a {
    color: clementine;
    text-decoration: none;
}

.body a:hover {
    color: clementine;
    text-decoration: underline;
}

.footer {
    display: flex;
    gap: 12px;
}

.footer > * {
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0 !important;
}
