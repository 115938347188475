@value white, mischka, oxfordBlue from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value Lato from 'src/shared/design-system/thermal-ceramics/fonts/lato/styles.module.css';
@value button from 'src/shared/design-system/thermal-ceramics/components/toggle-button/button/styles.module.css';

.group {
    display: flex;
    border: 1px solid mischka;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.group .button {
    border: none;
    border-radius: 0;
}

.button:not(:last-child) {
    border-right: 1px solid mischka;
}
