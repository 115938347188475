@value screenSmMax, screenSmMin from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value paleSky, linkWater, christine from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value logout from 'src/shared/design-system/thermal-ceramics/components/logout/styles.module.css';
@value userCaption, name as userCaptionName from 'src/shared/design-system/thermal-ceramics/components/user-caption/styles.module.css';
@value avatar from 'src/shared/design-system/thermal-ceramics/components/avatar/styles.module.css';
@value container from 'src/shared/design-system/thermal-ceramics/components/container/styles.module.css';

.user {
    border-bottom: to-rem(1px) solid linkWater;
}

.user .userLink {
    text-decoration: none;
}

.user .userCaptionName,
.user .logout,
.user .avatar {
    transition: all 0.3s ease;
}

.user .logout:hover,
.user .userLink:hover .userCaptionName {
    color: christine;
}

.user .userLink:hover .avatar {
    border-color: christine;
}

.user .container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: to-rem(40px);
    padding-top: to-rem(28px);
    padding-bottom: to-rem(28px);
}

@media screenSmMax {
    .subheader {
        background-color: linkWater;
    }

    .user {
        border-bottom: 0;
    }

    .user .logout {
        color: paleSky;
    }

    .user .avatar {
        width: to-rem(32px);
    }

    .user .userCaptionName {
        display: none;
    }

    .user .container {
        gap: to-rem(16px);
        padding-top: to-rem(10px);
        padding-bottom: to-rem(10px);
    }
}
