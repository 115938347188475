@value screenSmMax, screenSmMin from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value paleSky, linkWater, christine from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value container from 'src/shared/design-system/thermal-ceramics/components/container/styles.module.css';
@value link as navigationLink, item as navigationItem from 'src/shared/design-system/thermal-ceramics/components/navigation/styles.module.css';

.siteNavigation {
    padding-top: 68px;
    padding-bottom: 68px;
}

@media screenSmMin {
    .siteNavigation .navigationLink {
        min-width: 130px;
    }
}

@media screenSmMax {
    .siteNavigation {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 34px;
        background-color: linkWater;
    }

    .siteNavigation .container {
        padding: 0;
    }

    .siteNavigation .navigationItem {
        flex-grow: 1;
    }

    .siteNavigation .navigationLink {
        font-size: 0.75rem;
    }
}