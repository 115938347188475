@value screenSmMax from '/src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value linkWater, white from '/src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.container {
    padding: 170px 10px;
    max-width: none;
    background: linkWater;
}

.content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    max-width: 648px;
    margin: 0 auto;
    padding: 32px 32px;
    border-radius: 20px;
    background: white;
}

.heading,
.description {
    margin: 0;
}

.link {
    align-self: flex-end;
}

@media screenSmMax {
    .container {
        padding-top: 32px;
        padding-bottom: 32px;
    }

    .heading {
        font-size: 24px;
        line-height: 1.25;
    }
}