.header {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: space-between;
    margin-bottom: 24px;
}

.listing:not(:last-child) {
    margin-bottom: 88px;
}
