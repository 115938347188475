@value paleSky, eigengrau, athensGray, funGreen, flamingo from '/src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    border: 0;
    width: 100%;
    color: paleSky;
    background: none;
}

.button {
    display: block;
    position: relative;
    width: 100%;
    padding: 10px 8px;
    border: 0;
    border-radius: 6px;
    color: eigengrau;
    font-size: to-rem(16px);
    line-height: 1.5;
    text-align: left;
    text-decoration: none;
    background: none;
}

.button:hover {
    background: athensGray;
}

.positive .button {
    color: funGreen;
}

.danger .button {
    color: flamingo;
}