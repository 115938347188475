@value white, pastelGray, paleSky, linkWater, linkWaterDark from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.table {
    width: 100%;
    background: white;
    border: none;
    border-spacing: 0;
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    text-align: left;
}

.head {
    font-size: to-rem(12px);
    font-weight: 600;
    line-height: 1.5;
    color: white;
}

.headCell,
.cell {
    border: 0;
    padding-left: 24px;
    padding-right: 24px;
    transition: all 0.3s ease;
}

.headCell {
    padding-top: 14px;
    padding-bottom: 14px;
    background-color: paleSky;
}

.cell {
    padding-top: 26px;
    padding-bottom: 26px;
    font-size: to-rem(14px);
    line-height: 1.43;
}

.row:first-child > .headCell:first-child {
    border-radius: 8px 0 0 0;
}

.row:first-child > .headCell:last-child {
    border-radius: 0 8px 0 0;
}

.row:nth-child(even) .cell {
    background-color: linkWater;
}

.row:last-child .cell:first-child {
    border-radius: 0 0 0 8px;
}

.row:last-child .cell:last-child {
    border-radius: 0 0 8px 0;
}

.scrollArea::before {
    content: '';
    z-index: 1;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid pastelGray;
    border-radius: 7px;
    pointer-events: none;
}

.scrollArea :global(.simplebar-mask) {
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
}

.scrollArea :global(.simplebar-content) > * {
    margin-top: -1px;
}

.scrollArea:global(.simplebar-scrollable-x) {
    margin-bottom: 32px;
}

.scrollArea:global(.simplebar-scrollable-x) :global(.simplebar-horizontal) {
    top: 100%;
    margin-top: 10px;
}

@media (hover: hover) {
    .row:hover .cell {
        background-color: linkWaterDark;
    }
}