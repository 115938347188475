@value trout, mischka, christine from '/src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.crumb {
    color: trout;
    text-decoration: none;
}

.link {
    transition: all .3s ease;
}

.link:hover {
    color: christine;
    text-decoration: underline;
}

.current {
    color: christine;
}

.crumb svg {
    display: block;
}