@value white, trout, linkWater from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.toggleMobileMenu {
    position: relative;
    padding: 0 1.5rem 0 0;
    border: 0;
    margin: 0;
    background: none;
    color: trout;
    cursor: pointer;
    font-family: inherit;
    font-size: 0.875rem;
    font-weight: 400;
}

.burger {
    top: 50%;
    right: 0;
    margin-top: -0.0625rem;
}

.burger,
.burger::before,
.burger::after {
    display: block;
    position: absolute;
    width: 0.875rem;
    height: 0.125rem;
    background: trout;
    transition: all 0.3s ease;
}

.burger::before,
.burger::after {
    content: '';
}

.burger::before {
    bottom: 100%;
    margin-bottom: 0.1875rem;
    transform-origin: -1px 3px;
}

.burger::after {
    top: 100%;
    margin-top: 0.1875rem;
    transform-origin: 2px 0;
}

.burger.cross {
    background-color: transparent;
}

.burger.cross::before {
    transform: rotate(45deg);
}

.burger.cross::after {
    transform: rotate(-45deg);
}