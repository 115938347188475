@value eigengrau from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value avatar from 'src/shared/design-system/thermal-ceramics/components/avatar/styles.module.css';

.userCaption {
    display: flex;
    gap: 0.625rem;
    align-items: center;
}

.avatar {
    width: 2.75rem;
}

.name {
    color: eigengrau;
}