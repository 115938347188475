@value eigengrau, athensGray, paleSky, pastelGray from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value scrollArea from 'src/shared/design-system/thermal-ceramics/components/scroll-area/styles.module.css';

.select {
    position: relative;
}

.toggle {
    padding: 0 20px 0 4px;
    border: none;
    background: none;
    align-self: stretch;
}

.chevron {
    display: block;
    color: paleSky;
}

.popover {
    z-index: 2;
    width: 100%;
}

.select .scrollArea {
    padding: 4px 6px;
    border: 1px solid pastelGray;
    border-radius: 8px;
    background: white;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.list {
    composes: listReset from 'src/shared/design-system/thermal-ceramics/helpers/list-reset.module.css';
}

.item {
    position: relative;
    padding: 10px 8px;
    border-radius: 6px;
    color: eigengrau;
    font-size: to-rem(16px);
    line-height: 1.5;
}

.item:not(:last-child) {
    margin-bottom: 2px;
}

.active,
.selected {
    background: athensGray;
}

.check {
    display: block;
    position: absolute;
    top: 50%;
    right: 14px;
    color: dodgerBlue;
    transform: translateY(-50%);
}

.nothingFound {
    display: block;
    padding: 10px 8px;
    color: eigengrau;
    font-size: to-rem(16px);
    line-height: 1.5;
}

.spinner {
    --thickness: 2px;

    margin: 0 12px;
    width: 20px;
}

.enter {
    transition: all 0.1s ease-out;
}

.enterFrom {
    transform: scale(0.95);
    opacity: 0;
}
.enterTo {
    transform: scale(1);
    opacity: 1;
}

.leave {
    transition: all 0.075s ease-out;
}

.leaveFrom {
    transform: scale(1);
    opacity: 1;
}

.leaveTo {
    transform: scale(0.95);
    opacity: 0;
}