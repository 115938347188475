@value Lato from 'src/shared/design-system/thermal-ceramics/fonts/lato/styles.module.css';
@value clementine, trout, linkWater from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value screenSm from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';

.footer {
  font-family: Lato;
  color: trout;
}

.topLinks,
.accreditations,
.middleSection,
.bottom {
  border-top: 0.0625rem solid linkWater;
}

.topLinks {
  padding: 1.75rem 0;
}

.accreditations {
  padding: 2.75rem 0 2.1875rem;
}

.middleSectionGrid {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 3fr 1fr;
}

.footer .externalLinks {
  padding-top: 2.75rem;
  padding-bottom: 2.1875rem;
}

.footer .socialLinks {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  justify-content: flex-end;
  border-left: 0.0625rem solid linkWater;
}

.footer .socialLinks:not(:first-child) {
  border-left: 0.0625rem solid linkWater;
}

.bottom {
  padding-top: 2.5rem;
  padding-bottom: 4.625rem;
}

.bottomWrapper {
  position: relative;
}

.bottomContent {
  max-width: 33.75rem;
}

.backToTop {
  --color: clementine;

  position: absolute;
  top: -0.1875rem;
  right: 0;
}

.copyright {
  margin-top: 0;
  margin-bottom: 2.1875rem;
}

.copyrightParagraph {
  display: block;
  margin-top: 0;
  font-size: 0.75rem;
  line-height: 1.5;
}

.copyrightParagraph:not(:last-child) {
  margin-bottom: 1.25rem;
}

@media (max-width: screenSm) {
  .middleSectionGrid {
    display: block;
  }

  footer .externalLinks {
    display: none;
  }

  .footer .socialLinks {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    justify-content: center;
  }

  .footer .bottom {
    padding-top: 6.75rem;
  }

  .footer .backToTop {
    left: 50%;
    top: auto;
    right: auto;
    bottom: 100%;
    transform: translateX(-50%);
    margin-bottom: 2.125rem;
  }
}