@value screenSmMax from '/src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value calculationResult from 'src/app/details/calculation/result/styles.module.css';
@value information, keyValueSection from 'src/app/details/styles.module.css';
@value list from 'src/shared/design-system/thermal-ceramics/components/key-value-section/styles.module.css';

.info {
    max-width: 855px;
    margin-bottom: 24px;
}

.saveCalculationForm .list {
    grid-template-columns: repeat(auto-fill, minmax(max(200px, calc((100% - 80px) / 4)), 1fr));
}

.saveCalculationForm .list:not(:last-child) {
    margin-bottom: 32px;
}

.saveCalculationForm .keyValueSection {
    max-width: 100%;
}

.result {
    margin-top: 32px;
}

.resultHeading {
    font-size: to-rem(28px);
    font-weight: 400;
    margin-bottom: 24px;
}