@value screenXsMax from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value header from 'src/app/listing/styles.module.css';
@value group from 'src/shared/design-system/thermal-ceramics/components/toggle-button/group/styles.module.css';

.materialListing .header {
    justify-content: space-between;
}

.materialListing .group {
    margin: 0;
}

@media screenXsMax {
    .materialListing .header {
        flex-wrap: wrap;
        flex-direction: row;
    }
}