@value section from 'src/app/details/styles.module.css';
@value calculationListing from 'src/app/listing/calculation/styles.module.css';

.projectPage .section {
    margin-bottom: 80px;
}

.projectPage .calculationListing {
    max-width: 700px;
}

.listing {
    margin-bottom: 40px;
}
