@value white, downriver from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value screenSm from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';

.socialLinks {
    composes: listReset from 'src/shared/design-system/thermal-ceramics/helpers/list-reset.module.css';
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2.5rem;
}

.link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999rem;
    width: 4rem;
    height: 4rem;
    color: white;
    background-color: downriver;
    transition: all ease .3s;
}

.link:hover .iconContainer {
    opacity: 0.8;
}

.iconContainer {
    transition: all ease .3s;
}

.iconContainer > * {
    display: block;
}

@media (max-width: screenSm) {
    .link {
        width: 3rem;
        height: 3rem;
    }
}