@value paleSky, blueHaze, christine from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value Lato from 'src/shared/design-system/thermal-ceramics/fonts/lato/styles.module.css';

.field {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.inputs {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 20px;
}

.add {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    color: christine;
    font-family: Lato;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.75;
}

.remove {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    padding: 0 10px;
    border: 0;
    color: paleSky;
    background: none;
}