@value screenSm from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value clementine from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.topLinks {
    composes: listReset from 'src/shared/design-system/thermal-ceramics/helpers/list-reset.module.css';
    display: grid;
    align-items: center;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(4, 1fr);
}

.button {
    font-size: 1rem;
    line-height: 1.25;
    width: 100%;
    text-align: center;
}

.link:not(.button) {
    color: clementine;
    font-size: 1rem;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

@media (max-width: screenSm) {
    .topLinks {
        grid-gap: 1.25rem;
        grid-template-columns: 1fr;
    }
}