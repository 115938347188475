@value paleSky, linkWater, mediumPurple from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value Lato from 'src/shared/design-system/thermal-ceramics/fonts/lato/styles.module.css';
@value screenXsMin, screenXsMax from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';

.pagination {
    display: flex;
    gap: 16px;
    align-items: center;
}

.buttons {
    display: flex;
    gap: 2px;
    margin-right: auto;
}

.button,
.divider {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    min-width: 40px;
    min-height: 40px;
}

.button {
    border: 0;
    border-radius: 8px;
    background: none;
    font-family: Lato;
    color: paleSky;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.3s ease;
}

.button:hover {
    background: linkWater;
}

.active {
    background: linkWater;
    color: mediumPurple;
}

.text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 1.43;
}

.goButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 7px 14px;
    font-size: 14px;
    line-height: 1.43;
    min-width: 0;
}

.number {
    font-weight: 500;
}

@media screenXsMin {
    .text {
        display: none;
    }

    .goButton:disabled {
        display: none;
    }
}

@media screenXsMax {
    .pagination {
        justify-content: space-between;
    }

    .buttons {
        display: none ;
    }

    .goButton {
        padding: 10px;
    }

    .goButton:disabled {
        visibility: hidden;
    }

    .goButtonText {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
}