@value paleSky from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value screenSmMax from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value button as buttonComponent from 'src/shared/design-system/thermal-ceramics/components/button/styles.module.css';

.description {
    margin-bottom: 4px;
}

.formWrap {
    padding-top: 16px;
}

.footer {
    padding-top: 16px;
}

.buttonComponent.button {
    width: 44px;
    height: 44px;
}

.mark {
    color: paleSky;
}

@media screenSmMax {
    .buttonComponent.button {
        width: 34px;
        height: 34px;
    }
}