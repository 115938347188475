@value screenSm from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value eigengrau, paleSky from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.logout {
    cursor: pointer;
    position: relative;
    padding: 0;
    border: 0;
    margin: 0;
    color: eigengrau;
    background: none;
}

.logout::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: to-rem(48px);
    height: to-rem(48px);
    transform: translate(-50%, -50%);
}

.icon {
    display: block;
}
