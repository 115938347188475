@value mischka from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.avatar {
    display: flex;
    position: relative;
    overflow: hidden;
    border: to-rem(1px) solid mischka;
    border-radius: 50%;
}

.avatar::before {
    content: '';
    display: block;
    padding-top: 100%;
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}