@value trout, athensGray, mischka, paleSky from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value section as entityDetails from 'src/app/details/styles.module.css';
@value screenXsMax from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';

.materialDetails .entityDetails {
    margin-bottom: 48px;
}

.thermalConductivityValuesHeading {
    margin-bottom: 28px;
    font-size: to-rem(20px);
}

.thermalConductivityValues {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

.thermalConductivity {
    display: flex;
    align-items: center;
    gap: 16px;
}

.temperature {
    color: trout;
}

.kValue {
    margin: 0;
    padding: 10px 14px;
    background: athensGray;
    border: 1px solid mischka;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-weight: 400;
    font-size: to-rem(16px);
    line-height: 1.5;
    color: paleSky;
}

@media screenXsMax {
    .thermalConductivityValues {
        flex-direction: column;
    }

    .temperature {
        width: 25%;
    }

    .kValue {
        flex-grow: 1;
        text-align: center;
    }
}