@value scandal, earlyDawn, athensGray, fairPink, trinidad, webOrange, funGreen, nileBlue, linkWater from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.notification {
    display: flex;
    gap: 16px;
    padding: 16px;
    margin: 0;
    border-radius: 3px;
    color: nileBlue;
    font-size: to-rem(14px);
    line-height: 1.43;
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}

.icon {
    display: block;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.title {
    font-size: to-rem(16px);
    line-height: 1.45;
}

.body:first-child:last-child {
    margin-top: to-rem(2px);
}

.success {
    background: scandal;
}

.successIcon {
    color: funGreen;
}

.attention {
    background: athensGray;
}

.warning {
    background: earlyDawn;
}

.warningIcon {
    color: webOrange;
}

.error {
    background: fairPink;
}

.errorIcon {
    color: trinidad;
}

.notification ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.notification li {
    position: relative;
    padding-left: 12px;
}

.notification li::before {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    left: 0;
    background: nileBlue;
    border-radius: 999px;
    width: 4px;
    height: 4px;
}

.notification li:not(:last-child) {
    margin-bottom: 4px;
}