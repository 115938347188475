@value screenXsMax from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';

.fillWithUser,
.notification,
.fieldset:not(:last-child) {
    margin-bottom: 24px;
}

.innerGrid {
    display: flex;
    flex-direction: column;
    gap: 28px;
}

.form .footer {
    margin-top: 40px;
}

@media screenXsMax {
    .fieldset:not(:last-child) {
        margin-bottom: 48px;
    }
}