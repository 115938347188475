@value paleSky, christine from '/src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.thermalConductivityValuesHeading {
    margin-bottom: 28px;
    font-size: to-rem(20px);
}

.thermalConductivityFieldset {
    display: flex;
    flex-direction: column;
    gap: 28px;
    margin-bottom: 80px;
}

.thermalConductivityLabels {
    display: flex;
    gap: 20px;
    flex-grow: 1;
    padding-right: 60px;
}

.thermalConductivityItem {
    display: flex;
    position: relative;
    gap: 20px;
    padding-right: 60px;
}

.thermalConductivityInputs {
    display: flex;
    gap: 20px;
    flex-grow: 1;
    max-width: 100%;
    min-width: 0;
}

.thermalConductivityLabels > *,
.thermalConductivityInputs > * {
    flex: 1 1 0;
    max-width: 100%;
    min-width: 0;
}

.thermalConductivityLabels > * {
    text-align: center;
    font-size: to-rem(14px);
}

.thermalConductivityRemove {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    padding: 0 10px;
    border: 0;
    color: paleSky;
    background: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 46px;
}

.thermalConductivityAdd {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    color: christine;
    font-family: Lato;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.75;
}
