@value white, mischka, linkWater, oxfordBlue from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value Lato from 'src/shared/design-system/thermal-ceramics/fonts/lato/styles.module.css';

.button {
    padding: 11px 16px;
    border: 1px solid mischka;
    border-radius: 8px;
    color: oxfordBlue;
    background-color: white;
    font-size: to-rem(14px);
    font-family: Lato;
    font-weight: 500;
    line-height: 1.43;
}

.pressed {
    background-color: linkWater;
}