@value paleSky from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value input from 'src/shared/design-system/thermal-ceramics/components/form/input/styles.module.css';

.icon {
    display: block;
    position: absolute;
    top: 0;
    left: 16px;
    height: 100%;
    pointer-events: none;
    color: paleSky;
}

.inputContainer .input {
    padding-left: 44px;
}