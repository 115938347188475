@value screenSmMax from '/src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value section as keyValueSection from '/src/shared/design-system/thermal-ceramics/components/key-value-section/styles.module.css';

.section {}

.hasFullInformation .information {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.keyValueSection {
    position: relative;
    max-width: 700px;
}

.editButton {
    position: absolute;
    top: 20px;
    right: 20px;
}

.mainFooter {
    grid-column: span 2;
}

@media screenSmMax {
    .hasFullInformation .information {
        grid-template-columns: 1fr;
    }
}
