@value pastelGray from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.scrollArea {}

.scrollArea :global(.simplebar-scrollbar)::before {
    top: 4px;
    bottom: 4px;
    left: 4px;
    right: 4px;
    border-radius: 999px;
    background: pastelGray;
}

.scrollArea :global(.simplebar-vertical) {
    width: 16px;
}

.scrollArea :global(.simplebar-horizontal) {
    height: 16px;
}
