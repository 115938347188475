.fillLink {}

.link {
    color: inherit;
    text-decoration: none;
}

.link::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}