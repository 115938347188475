@value christine from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value screenXsMax from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value Lato from 'src/shared/design-system/thermal-ceramics/fonts/lato/styles.module.css';

.resetPassword {
    cursor: pointer;
    padding: 0;
    border: 0;
    margin: 40px 0 0;
    color: christine;
    font-weight: 600;
    font-family: Lato;
    font-size: to-rem(16px);
    line-height: 1.75;
    background: none;
}

.resetPassword:hover {
    text-decoration: underline;
}
