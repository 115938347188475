@value screenXsMax from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value header from 'src/app/listing/styles.module.css';
@value group from 'src/shared/design-system/thermal-ceramics/components/toggle-button/group/styles.module.css';

.materialListing {
    margin-bottom: 48px;
}

.materialListing .header {
    justify-content: space-between;
}

.materialListing .group {
    margin: 0;
}

.heading {
    margin-bottom: 16px;
    font-size: to-rem(28px);
    font-weight: 400;
}

.infoCellHead,
.infoCell {
    padding-right: 0;
}
.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
@media screenXsMax {
    .materialListing .header {
        flex-wrap: wrap;
        flex-direction: row;
    }
}