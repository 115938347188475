@value athensGray, pastelGray, eigengrau from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value enter from 'src/shared/design-system/thermal-ceramics/animations/enter/styles.module.css';
@value leave from 'src/shared/design-system/thermal-ceramics/animations/leave/styles.module.css';

.dropdown,
.buttonContainer {
    position: relative;
}

.buttonContainer > * {
    display: block;
}

.popover {
    z-index: 1;
}

.scrollArea {
    width: 200px;
    padding: 4px 6px;
    border: 1px solid pastelGray;
    border-radius: 8px;
    background: white;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.list {
    composes: listReset from 'src/shared/design-system/thermal-ceramics/helpers/list-reset.module.css';
}

.item:not(:last-child) {
    margin-bottom: 2px;
}

.enter {
    --transition: all 0.1s ease-out;
}

.leave {
    --transition: all 0.075s ease-out;
}
