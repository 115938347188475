@value screenXsMax from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value group as toggleButtonGroup from 'src/shared/design-system/thermal-ceramics/components/toggle-button/group/styles.module.css';
@value field from 'src/shared/design-system/thermal-ceramics/components/form/field/styles.module.css';
@value checkbox, label as checkboxLabel from 'src/shared/design-system/thermal-ceramics/components/form/checkbox/styles.module.css';
@value link as fillLink from 'src/shared/design-system/thermal-ceramics/components/fill-link/styles.module.css';

.header {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 16px;
    margin-bottom: 28px;
}

.navigation {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 24px;
}

.footer {
    padding: 14px 24px;
}

.listing .toggleButtonGroup {
    flex-shrink: 0;
}

.listing .toggleButtonGroup:not(:last-child) {
    margin-right: auto;
}

.listing .checkbox {
    align-self: center;
}

.listing .checkbox:first-child {
    margin-right: auto;
}

.listing .field {
    min-width: 0;
}

.placeholder {
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
}

.spinner {
    width: 48px;
}

.row {
    position: relative;
}

.actionsHeadCell {
    text-align: center;
}

.listing .actionsCell {
    z-index: 1;
    position: relative;
    padding: 0;
}

.status {
    display: block;
    text-align: center;
}

.listing .checkbox {
    z-index: 1;
    display: inline-flex;
    align-items: center;
}

.listing .checkboxLabel {
    color: inherit !important;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
}

.headCell {
    position: relative;
    white-space: nowrap;
}

.orderIcon {
    position: absolute;
    top: 17px;
    margin-left: 6px;
    color: white;
    transform: scaleY(-1);
}

.orderIcon.ascending {
    transform: none
}

@media screenXsMax {
    .navigation > * {
        width: 100%
    }

    .header {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
    }

    .header > *:not(.toggleButtonGroup) {
        width: 100%;
    }

    .header .toggleButtonGroup {
        order: -1;
        margin-right: 0;
    }

    .listing .fillLink {
        display: none;
    }

    .footer {
        padding-top: 0;
        padding-left: 16px;
        padding-right: 16px;
    }
}