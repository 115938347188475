@value legend from 'src/shared/design-system/thermal-ceramics/components/form/fieldset/styles.module.css';
@value group as toggleButtonGroup from 'src/shared/design-system/thermal-ceramics/components/toggle-button/group/styles.module.css';
@value screenXsMax from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';

.operatingParameters {
    margin-bottom: 24px;
}

.operatingParameters .legend {
    margin-bottom: 0;
}

.operatingParameters .toggleButtonGroup {
    flex-shrink: 0;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

@media screenXsMax {
    .filler {
        display: none;
    }
}