@value valueMeasurement from 'src/app/form/fields/material/helpers/value-measurement/styles.module.css';
@value button from 'src/shared/design-system/thermal-ceramics/components/button/styles.module.css';
@value table, row, cell from 'src/shared/design-system/thermal-ceramics/components/table/styles.module.css';
@value white, pastelGray, paleSky, linkWater, linkWaterDark from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.header {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    margin-bottom: 24px;
}

.actions {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.actions .button {
    align-items: center;
}

.label {
    font-weight: 700;
}

.overviewTableWrap {
    margin-bottom: 48px;
}

.labelHeadCell {
    width: 220px;
}

.materialHeadCell {
    width: 255px;
}

.compare .valueMeasurement {
    display: inline;
    padding: 0;
}

.compare .table {
    table-layout: fixed;
}

.compare .row:nth-child(even) .cell {
    background-color: white;
}

.compare .row .cell {
    border-bottom: 1px solid pastelGray;
}

.materialTable {
    margin-bottom: 24px;
}

@media (hover: hover) {
    .compare .row:hover .cell:not(.accentCell) {
        background-color: linkWaterDark;
    }
}