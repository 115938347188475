@value flamingo, paleSky from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value screenXsMax from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value listing, header from 'src/app/listing/styles.module.css';
@value group from 'src/shared/design-system/thermal-ceramics/components/toggle-button/group/styles.module.css';

.calculationListing .header {
    justify-content: space-between;
}

.calculationListing .group {
    margin: 0;
}

.calculationListing .listing {
    margin-bottom: 32px;
}

.footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}

.needsRecalculation {
    color: flamingo;
}

.value {
    color: paleSky;
}

@media screenXsMax {
    .calculationListing .header {
        flex-wrap: wrap;
        flex-direction: row;
    }
}