@font-face {
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    src:
        local('Lato'),
        url('src/shared/design-system/thermal-ceramics/fonts/lato/assets/Lato-Regular.woff2') format("woff2"),
        url('src/shared/design-system/thermal-ceramics/fonts/lato/assets/Lato-Regular.woff') format("woff");
}

@font-face {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    src:
        local('Lato'),
        url('src/shared/design-system/thermal-ceramics/fonts/lato/assets/Lato-Semibold.woff2') format("woff2"),
        url('src/shared/design-system/thermal-ceramics/fonts/lato/assets/Lato-Semibold.woff') format("woff");
}

@font-face {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    src:
        local('Lato'),
        url('src/shared/design-system/thermal-ceramics/fonts/lato/assets/Lato-Semibold.woff2') format("woff2"),
        url('src/shared/design-system/thermal-ceramics/fonts/lato/assets/Lato-Semibold.woff') format("woff");
}

@font-face {
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    src:
        local('Lato'),
        url('src/shared/design-system/thermal-ceramics/fonts/lato/assets/Lato-Bold.woff2') format("woff2"),
        url('src/shared/design-system/thermal-ceramics/fonts/lato/assets/Lato-Bold.woff') format("woff");
}