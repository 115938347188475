@value screenSm from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value trout, clementine from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.additionalLinks {
    composes: listReset from 'src/shared/design-system/thermal-ceramics/helpers/list-reset.module.css';
    display: flex;
    flex-wrap: wrap;
    gap: 1.0625rem 0;
}

.link {
    display: block;
    color: trout;
    font-size: 0.75rem;
    line-height: 1;
    text-decoration: none;
}

.item:not(:last-child) .link {
    padding-right: 0.3125rem;
    margin-right: 0.25rem;
    border-right: 0.0625rem solid trout;
}

.link:hover {
    color: clementine;
    text-decoration: underline;
}
