@value paleSky, white from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value headCell, cell from 'src/shared/design-system/thermal-ceramics/components/table/styles.module.css';
@value valueMeasurement from 'src/app/form/fields/material/helpers/value-measurement/styles.module.css';

.scrollArea .valueMeasurement {
    display: inline;
    padding: 0;
    justify-content: flex-start;
}

.scrollArea .headCell,
.scrollArea .cell {
    white-space: nowrap;
}