@value trout from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.backToTop {
  --color: trout;

  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  color: var(--color);
  font-size: to-rem(14px);
  font-family: inherit;
  background: none;
  cursor: pointer;
  transition: all .5s ease;
}

.text {
  margin-right: 0.57em;
}

.icon {
  width: 1em;
  height: 2.28em;
}