@value fiord, flamingo from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.hint {
    color: fiord;
    font-size: to-rem(14px);
    line-height: 1.43;
}

.error {
    color: flamingo;
}