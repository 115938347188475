@value screenXsMax from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';

.container {
    padding-top: 32px;
    padding-bottom: 32px;
}

.heading {
    margin: 0 0 32px;
    font-size: to-rem(30px);
}

@media screenXsMax {
    .heading {
        font-size: to-rem(24px);
    }
}