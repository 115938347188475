@value paleSky, eigengrau, athensGray, blueHaze, christine from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value Lato from 'src/shared/design-system/thermal-ceramics/fonts/lato/styles.module.css';
@value label from 'src/shared/design-system/thermal-ceramics/components/form/label/styles.module.css';

.liningDesign .label {
    font-weight: 500;
}

.materials {
    margin-left: -20px;
    margin-right: -20px;
}

.materialsInner {
    padding: 0 20px;
    width: max-content;
}

.grid {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 16px;
}

.labelsRow,
.row {
    display: grid;
    grid-template-columns: 28px 144px 244px 244px 244px;
    gap: 10px;
    max-width: 100%;
}

.hasTemperature .labelsRow,
.hasTemperature .row {
    grid-template-columns: 28px 144px 244px 244px 244px 244px 10px;
}

.labelsRow {
    margin-bottom: 6px;
}

.checkColumn {
    display: flex;
    align-items: center;
    height: 44px;
}

.labelsRow .checkColumn {
    height: 0;
}

.swapColumn {
    display: flex;
    gap: 10px;
}

.swapButton,
.plusButton {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0;
    width: 44px;
    height: 44px;
    padding: 0;
}

.swapButton > * {
    transform: rotate(90deg);
}

.description {
    margin-bottom: 48px;
}

.hint {
    display: block;
    margin-top: -24px;
    margin-bottom: 48px;
}

.noMaterials {
    padding-left: 192px;
    font-weight: 700;
}

.disabledMaterialHint {
    z-index: 1;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    border: 1px solid blueHaze;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    background-color: white;
    transition: all .3s ease;
    align-self: stretch;
    margin: 0;
    outline: none;
    appearance: none;
    flex-grow: 1;
    min-width: 0;
    padding: 10px 12px;
    color: paleSky;
    font-size: to-rem(16px);
    line-height: 1.375;
    background: athensGray;
}