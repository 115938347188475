@value screenSmMax from '/src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value information, keyValueSection from 'src/app/details/styles.module.css';
@value list from 'src/shared/design-system/thermal-ceramics/components/key-value-section/styles.module.css';

.calculationResult .list {
    grid-template-columns: repeat(auto-fill, minmax(max(200px, calc((100% - 80px) / 4)), 1fr));
    grid-column-gap: 40px;
}

.calculationResult .list:not(:last-child) {
    margin-bottom: 32px;
}

.calculationResult .keyValueSection {
    max-width: 800px;
}

.error {
    margin-bottom: 16px;
}

.header {
    margin-bottom: 40px;
    margin-right: 40px;
}

.attention {
    margin-bottom: 20px;
}