@value athensGray, pastelGray, paleSky, christine, eigengrau from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value enter from 'src/shared/design-system/thermal-ceramics/animations/enter/styles.module.css';
@value leave from 'src/shared/design-system/thermal-ceramics/animations/leave/styles.module.css';
@value materialDetails, thermalConductivityValuesHeading, thermalConductivityValues, kValue, temperature from 'src/app/details/material/styles.module.css';
@value section as entityDetails from 'src/app/details/styles.module.css';
@value section as keyValueSection from '/src/shared/design-system/thermal-ceramics/components/key-value-section/styles.module.css';
@value list as keyValueList from '/src/shared/design-system/thermal-ceramics/components/key-value-section/styles.module.css';
@value item as keyValueItem from '/src/shared/design-system/thermal-ceramics/components/key-value-section/styles.module.css';
@value key as keyValueKey from '/src/shared/design-system/thermal-ceramics/components/key-value-section/styles.module.css';
@value value as keyValueValue from '/src/shared/design-system/thermal-ceramics/components/key-value-section/styles.module.css';

.buttonContainer {
    z-index: 1;
    position: relative;
}

.buttonContainer > * {
    display: block;
}

.popover {
    z-index: 999;
}

.scrollArea {
    width: 300px;
    padding: 16px 12px;
    border: 1px solid pastelGray;
    border-radius: 8px;
    background: white;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.spinner {
    --color: christine;
    --animation-time: 1.2s;
    --thickness: 2px;

    display: block;
    position: relative;
    width: 48px;
    margin: auto;
}

.enter {
    --transition: all 0.1s ease-out;
}

.leave {
    --transition: all 0.075s ease-out;
}

.button {
    display: block;
    padding: 0;
    border: 0;
    margin: 0;
    background: none;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 999px;
    border: 1px solid paleSky;
    background: none;
    font-size: to-rem(16px);
}

.scrollArea .keyValueSection {
    padding: 0;
    border: 0;
}

.scrollArea .keyValueList {
    gap: 12px;
}

.scrollArea .keyValueItem {
    gap: 2px;
}

.scrollArea .keyValueKey {
    font-size: to-rem(14px);
}

.scrollArea .keyValueValue {
    font-size: to-rem(14px);
}

.scrollArea .materialDetails .entityDetails {
    margin-bottom: 12px;
}

.scrollArea .thermalConductivityValuesHeading {
    margin-bottom: 10px;
    font-size: to-rem(14px);
}

.scrollArea .thermalConductivityValues {
    flex-direction: column;
    gap: 12px;
}

.scrollArea .temperature {
    font-size: to-rem(14px);
}

.scrollArea .kValue {
    padding: 4px 8px;
    font-size: to-rem(12px);
}