@value linkWater from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value screenSmMax from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value breadcrumbs from 'src/app/routing/breadcrumbs/styles.module.css';
@value subheader from 'src/app/layout/subheader/styles.module.css';
@value siteNavigation from 'src/app/layout/site-navigation/styles.module.css';
@value page from 'src/shared/design-system/thermal-ceramics/components/page/styles.module.css';

.layout {
  display: grid;
  height: 100dvh;
  grid-template-rows: auto 1fr auto;
}

.layout > * {
  min-width: 0;
}

.placeholder {
  padding: 170px 0;
  background-color: linkWater;
  margin: 0 auto;
}

.spinner {
  max-width: 64px;
  margin: 90px auto;
}

.main .breadcrumbs {
  padding-top: 54px;
  padding-bottom: 54px;
}

.main .siteNavigation + .page .breadcrumbs {
  padding-top: 0;
}

@media screenSmMax {
  .main .breadcrumbs {
    padding-top: 34px;
    padding-bottom: 40px;
  }

  .placeholder {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}