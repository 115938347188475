@value paleSky from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.valueMeasurement {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: to-rem(14px);
    padding: 4px 8px;
    color: paleSky;
}