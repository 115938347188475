@value white, trout, clementine, christine, paleSky, linkWater from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value screenSmMax from '/src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';

.page {
    padding: 170px 0 73px;
    background-color: linkWater;
}

.content {
    max-width: 648px;
    margin: 0 auto 73px;
    padding: 60px 32px;
    border-radius: 20px;
    background: white;
}

.inner {
    max-width: 360px;
    margin: 0 auto;
}

.unauthorized {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.feedback {
    margin: 0;
    color: paleSky;
    font-size: to-rem(16px);
    line-height: 1.5;
    text-align: center;
}

.feedback a {
    color: christine;
    text-decoration: none;
}

.feedback a:hover {
    text-decoration: underline;
}

@media screenSmMax {
    .page {
        padding-top: 32px;
        padding-bottom: 32px;
    }
}