@value white, paleSky, eigengrau, blueHaze, sail, athensGray, sweetPink from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value Lato from 'src/shared/design-system/thermal-ceramics/fonts/lato/styles.module.css';

.container {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    border: 1px solid blueHaze;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    background-color: white;
    transition: all .3s ease;
}

.textarea {
    z-index: 1;
    align-self: stretch;
    outline: none;
    appearance: none;
    flex-grow: 1;
    min-width: 0;
    padding: 10px 12px;
    border: 0;
    color: eigengrau;
    font-family: Lato;
    font-size: to-rem(16px);
    line-height: 1.375;
    transition: all .3s ease;
    background: none;
}

.textarea::placeholder {
    color: paleSky;
}

.disabled {
    background-color: athensGray;
}

.disabled .textarea {
    color: paleSky;
}

.invalid {
    border-color: sweetPink;
}

.hasFocus {
    border-color: sail;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 2px 4px rgba(187, 205, 251, 0.6);
}

.invalid.hasFocus {
    border-color: sweetPink;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #FEE4E2;
}

.spinner {
    --thickness: 2px;

    margin: 0 12px;
    width: 20px;
}
