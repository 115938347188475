@value white, christine, mischka, oxfordBlue, paleSky, blueHaze, eigengrau, athensGray from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value screenXsMax from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value Lato from 'src/shared/design-system/thermal-ceramics/fonts/lato/styles.module.css';

.button {
  display: inline-flex;
  justify-content: center;
  padding: 10px 24px;
  min-width: 170px;
  max-width: 100%;
  background: christine;
  border: 1px solid christine;
  color: white;
  font-family: Lato;
  font-weight: 700;
  text-align: center;
  font-size: to-rem(16px);
  line-height: 1.5;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.secondary {
  color: oxfordBlue;
  border-color: mischka;
  background: white;
}

.button:hover {
  color: christine;
  border-color: christine;
  background: white;
}

.button[disabled],
.button[disabled]:hover,
.secondary[disabled],
.secondary[disabled]:hover,
.tertiary[disabled],
.tertiary[disabled]:hover {
  cursor: not-allowed;
  color: eigengrau;
  background-color: athensGray;
  border-color: blueHaze;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 0;
  color: christine;
  height: 40px;
  padding: 4px;
  border-radius: 999px;
}

.circle > svg {
  display: block;
  max-width: 18px;
  max-height: 18px;
}

.circle:hover {
  border-color: christine;
}

.tertiary {
  color: white;
  border-color: paleSky;
  background-color: paleSky;
}

.outline {
  color: christine;
  background: white;
}

.outline:hover {
  color: white;
  background: christine;
}

.inline {
  gap: 12px;
  align-items: center;
  padding: 0;
  border: 0;
  background: none;
  color: christine;
}

.inline:hover {
  text-decoration: underline;
}

.spinner {
  --thickness: 1px;
  align-self: center;
  width: 16px;
  margin-left: 8px;
}

@media screenXsMax {
  .button {
    min-width: 122px;
    padding: 8px 18px;
    font-size: 14px;
  }

  .circle {
    padding: 4px;
    min-width: 0;
  }
}