.scrollArea {
    margin-right: -20px;
}

.body {
    padding-right: 20px;
    width: max-content;
}

.section {
    max-width: 464px;
}

.heading {
    margin: 0 0 24px;
}

.header,
.item {
    display: grid;
    grid-template-columns: 28px 1fr 1fr;
    gap: 12px;
    width: 464px;
}

.header {
    margin-bottom: 6px;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-bottom: 18px;
    composes: listReset from 'src/shared/design-system/thermal-ceramics/helpers/list-reset.module.css';
}

.column {
    min-width: 0;
}

.checkColumn {
    display: flex;
    align-items: center;
    height: 44px;
}

.footer {
    display: flex;
    justify-content: flex-end;
}