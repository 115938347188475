.enter {
    --transition: all 0.3s ease-out;

    transition: var(--transition);
}

.enterFromTransform {
    transform: scale(0.95);
}

.enterToTransform {
    transform: scale(1);
}

.enterFromOpacity {
    opacity: 0;
}

.enterToOpacity {
    opacity: 1;
}