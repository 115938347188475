@value Lato from 'src/shared/design-system/thermal-ceramics/fonts/lato/styles.module.css';
@value white, trout, linkWater from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value screenMd, screenSm from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';

.header {
  font-family: Lato;
  pointer-events: none;
}

.section {
  pointer-events: auto;
  border-bottom: 0.0625rem solid linkWater;
  background-color: white;
}

.wrap {
  z-index: 999;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main {
  composes: section;
  padding: 1.75rem 0;
  transition: all .5s ease;
}

.grid {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.siteName {
  color: trout;
  font-size: 1.125rem;
  margin: 0 auto 0 0;
  transition: all .5s ease;
}

.mobileActions {
  gap: 1.5rem;
}

.mobileActions > * {
  width: 100%;
  text-align: center;
}

.mobileMenu {
  pointer-events: auto;
  background-color: linkWater;
}

.mobileMenuContent {
  padding: 1.875rem 0;
  overflow: auto;
  background-color: white;
}

.mobileSiteName {
  composes: section;
  padding: 0.6875rem 0 0.75rem;
}

@media (min-width: screenSm) {
  .header {
    min-height: 6.6875rem;
  }

  .header:not(.sticky) .backToTop {
    font-size: 0;
  }

  .sticky .backToTop {
    margin-left: 2.42em;
  }

  .sticky .main {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  .sticky .logo {
    margin-right: 5rem;
  }

  .sticky .logoImage {
    width: 8.4375rem;
    height: 2.5rem;
  }

  .sticky .siteName {
    font-size: 1rem;
  }

  .sticky .actions {
    gap: 1.5rem;
  }

  .toggleMobileMenu,
  .mobileSiteName {
    display: none;
  }
}

@media (max-width: screenMd) {
  .desktopSiteName {
    display: none;
  }
}

@media (max-width: screenSm) {
  .header {
    min-height: 6.25rem;
  }

  .main {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }

  .main .backToTop {
    display: none;
  }

  .main .logoImage {
    width: 6.43rem;
    height: 1.875rem;
  }

  .main .desktopActions {
    display: none;
  }

  .siteName {
    font-size: 0.875rem;
  }
}