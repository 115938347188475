@value blueHaze, paleSky from '/src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value screenXsMax from '/src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';

.fieldset {
    display: block;
    max-width: 855px;
    padding: 40px;
    margin: 0;
    border: 1px solid blueHaze;
    border-radius: 20px;
}

.legend {
    float: left;
    padding: 0;
    margin: 0 0 24px;
    width: 100%;
    font-size: to-rem(28px);
}

.legend + * {
    clear: both;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(max(200px, calc((100% - 80px) / 2)), 1fr));
    grid-column-gap: 80px;
    grid-row-gap: 28px;
}

.footer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.footer:not(:first-child) {
    margin-top: 124px;
}

@media screenXsMax {
    .fieldset {
        padding: 0;
        border: 0;
    }

    .fieldset:not(:last-child) {
        margin-bottom: 48px;
    }

    .legend {
        font-size: to-rem(20px);
    }

    .footer:not(:first-child) {
        margin-top: 68px;
    }
}