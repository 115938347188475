@value blueHaze, eigengrau, trout, paleSky from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';
@value screenXsMax from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';

.section {
    padding: 40px;
    border: 1px solid blueHaze;
    border-radius: 20px;
}

.list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(max(200px, calc((100% - 80px) / 2)), 1fr));
    grid-column-gap: 80px;
    grid-row-gap: 32px;
    padding: 0;
    margin: 0;
}

.item {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.key {
    display: block;
    color: eigengrau;
    font-weight: 500;
    font-size: to-rem(18px);
    line-height: 1.56;
}

.value {
    display: block;
    margin: 0;
    color: trout;
    font-size: to-rem(16px);
    line-height: 1.5;
}

.asList .list {
    display: flex;
    flex-direction: column;
    gap: 26px;
}

.asList .item {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}

.asList .key {
    color: paleSky;
    font-weight: 500;
    font-size: to-rem(16px);
    line-height: 1.56;
}

.asList .value {
    color: eigengrau;
    font-weight: 500;
    font-size: to-rem(18px);
    line-height: 1.56;
}

@media screenXsMax {
    .section {
        padding: 60px 40px;
    }

    .asList {
        padding: 20px;
    }

    .asList .value {
        font-size: to-rem(16px);
    }
}