.logo {
    display: flex;
    align-items: center;
    margin-right: 7.5rem;
    transition: all .5s ease;
}

.image {
    object-fit: contain;
    object-position: center;
    width: 10.625rem;
    height: 3.125rem;
    transition: all .5s ease;
}