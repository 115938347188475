@value paleSky, salomie, brightSun from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.button {
    --border: paleSky;
    --background: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;
    background: none;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.active,
.button:hover {
    --border: brightSun;
    --background: salomie;
}

.active:hover {
    --border: paleSky;
    --background: transparent;
}

.icon {
    display: block;
}

.icon path {
    stroke: var(--border);
    fill: var(--background);
}

.spinner {
    --thickness: 1px;

    width: 24px;
    margin: auto;
}