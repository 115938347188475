@value trout, mischka, christine from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.breadcrumbs {
    display: block;
}

.list {
    display: flex;
    flex-wrap: wrap;
    gap: to-rem(12px);
    composes: listReset from 'src/shared/design-system/thermal-ceramics/helpers/list-reset.module.css';
    font-weight: 500;
    font-size: to-rem(14px);
    line-height: 1.43;
}

.item {
    display: flex;
    align-items: center;
    gap: to-rem(12px);
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: to-rem(16px);
    height: to-rem(16px);
}

.icon {
    display: block;
    color: mischka;
}
