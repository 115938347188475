.leave {
    --transition: all 0.3s ease-out;

    transition: var(--transition);
}

.leaveFromTransform {
    transform: scale(1);
}

.leaveToTransform {
    transform: scale(0.95);
}

.leaveFromOpacity {
    opacity: 1;
}

.leaveToOpacity {
    opacity: 0;
}