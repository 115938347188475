@value Lato from 'src/shared/design-system/thermal-ceramics/fonts/lato/styles.module.css';
@value white, trout, clementine, linkWater from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.button {
    --border-color: clementine;

    --background-color-default: transparent;
    --background-color-hover: clementine;
    --background-color: var(--background-color-default);

    --color-default: clementine;
    --color-hover: white;
    --color: var(--color-default);

    display: inline-flex;
    position: relative;
    align-items: center;
    padding: 0.625rem 1.875rem 0.75rem;
    border: 0.0625rem solid var(--border-color);
    border-radius: 999rem;
    background: var(--background-color);
    color: var(--color);
    font-family: Lato;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.429;
    text-decoration: none;
    transition: all .3s ease;
}

.button:hover {
    --background-color: var(--background-color-hover);
    --color: var(--color-hover);
}

.secondary {
    --border-color: trout;

    --background-color-default: transparent;
    --background-color-hover: trout;

    --color-default: trout;
    --color-hover: white;
}

.iconContainer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;
    transition: opacity ease .3s;
}

.iconContainer > * {
    display: block;
}

.withIcon {
    padding-right: 3.125rem;
}

.withIcon:hover {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.withIcon:hover .iconContainer {
    color: var(--color-default);
    opacity: 0;
}

.text {
    width: 100%;
}