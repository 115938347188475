@value screenXsMax, screenSmMax from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value heading from 'src/shared/design-system/thermal-ceramics/components/heading/styles.module.css';
@value navigation from 'src/shared/design-system/thermal-ceramics/components/navigation/styles.module.css';
@value breadcrumbs from 'src/app/routing/breadcrumbs/styles.module.css';

.page {}

.container {
    position: relative;
    margin-bottom: 60px;
    min-height: 200px;
}

.inProgress {
    pointer-events: none;
}

.spinnerContainer {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
}

.spinner {
    width: 48px;
}

.container > .heading {
    margin: 0 0 32px;
}

.container > .navigation {
    margin-bottom: 44px;
}

@media screenSmMax {
    .container > .navigation {
        margin-bottom: 32px;
    }

    .container > .heading {
        font-size: to-rem(24px);
        font-weight: 500;
    }
}
