@value screenSm from 'src/shared/design-system/thermal-ceramics/breakpoints/styles.module.css';
@value trout, clementine from 'src/shared/design-system/thermal-ceramics/palette/styles.module.css';

.externalLinks {
    composes: listReset from 'src/shared/design-system/thermal-ceramics/helpers/list-reset.module.css';
    display: grid;
    grid-row-gap: 2.5625rem;
    grid-column-gap: 1.5rem;
    grid-template-columns: repeat(2, 1fr);
}

.link {
    display: inline-flex;
    position: relative;
    padding-left: 2.625rem;
    color: trout;
    font-size: 0.875rem;
    text-decoration: none;
}

.link:hover {
    color: clementine;
    text-decoration: underline;
}

.icon {
    position: absolute;
    top: 0.1875rem;
    left: 0;
    color: clementine;
}
